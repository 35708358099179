import s from 'styled-components'

export const TextNoData = s.h3`
  text-align: center;
  color: #776D6D;
  font-weight: unset;
  font-size: 24px;
`

export const BoxWrapper = s.div`
  min-height: calc(100vh - 217px);
  @media (max-width: 750px) {
    min-height: 100vh;
  }
`

export const BoxWrapperClub = s.div`
  min-height: calc(100vh - 500px);
  @media (max-width: 750px) {
    min-height: 100vh;
  }
`

export const BoxDetail = s.div`
  width:100%;
  padding-left:10%;
  padding-right:10%;

  @media (max-width: 750px) {
    padding-left:0;
    padding-right:0;
  }
`

export const BoxDetailClub = s.div`
  width:100%;

  @media (max-width: 750px) {
    padding-left:0;
    padding-right:0;
  }
`

export const BoxDetailGallery = s.div`
  padding-left:10%;
  padding-right:10%;
  padding-top:40px;
  background: #E8E8E8;
  @media (max-width: 750px) {
    padding-left:0;
    padding-right:0;
  }
`

export const BoxDetailExampleCarousel = s.div`
  background: #E8E8E8;
  padding-top: 30px;
  padding-bottom: 10px;
  @media (max-width: 750px) {
    padding-left:0;
    padding-right:0;
  }
`
