import React from 'react'
import { BoxDetail, BoxWrapper, TextNoData } from '../box'
import Button from '../../components/form/buttons'
import MediaQuery from 'react-responsive'
import { navigate } from 'gatsby'

const style1 = { marginTop: '12%', width: '100%' }
const Clublist_h3 = {
  marginTop: '47px',
  fontSize: '24px',
  fontFamily: '"Noto Serif", serif',
  float: 'left',
}
const Clublist_h3_mobile = {
  marginTop: '47px',
  marginLeft: '15px',
  fontSize: '24px',
  fontFamily: '"Noto Serif", serif',
  float: 'left',
}
const Clublist_Underline = {
  borderBottom: '3px',
  marginBottom: '14px solid rgb(193, 76, 64)',
}
const Clublist_img = {
  marginTop: '30px',
  display: 'flex',
}
const Clublist_img_mobile = {
  marginTop: '30px',
  marginLeft: '15px',
  display: 'flex',
}

const selectBanner ={
  KMITL: 'KMITL',
  Default: 'Default',
}
export default props => (
  <BoxWrapper>
    <BoxDetail>
    <MediaQuery minWidth={769}>
      <div >
        <h3 style={Clublist_h3}>{props.locale.CLUB_LIST}</h3>
      </div>
      <div style={Clublist_Underline} ></div>
      <br /><br /><br /><br />
      <div>
        <img src="https://s3-ap-southeast-1.amazonaws.com/images.invitree.me/static/club_logo/KMITLEngineerAlumni.png" width="10%" height="auto" style={Clublist_img}/>
        <br />
        <Button.Button8
          onClick={() => { (props.data.joined == true && props.auth.currentUser !== null) ? navigate(`my-club`) : props.signinHandler(`${props.locale.CLUB_PRMISSION_ALERT}`, `${props.locale.CLUB_NO_PRMISSION}`, props.data.joined, props.auth.currentUser, selectBanner.KMITL ) }}
          style={{ marginTop: 30, width: '10%' }}
        >
          {props.locale.CLUB_LIST_BUTTON}
        </Button.Button8>
      </div>
      </MediaQuery>
      <MediaQuery maxWidth={768}>
      <div >
        <h3 style={Clublist_h3_mobile}>{props.locale.CLUB_LIST}</h3>
      </div>
      <div style={Clublist_Underline} ></div>
      <br /><br /><br /><br />
      <div>
        <img src="https://s3-ap-southeast-1.amazonaws.com/images.invitree.me/static/club_logo/KMITLEngineerAlumni.png" width="20%" height="auto" style={Clublist_img_mobile}/>
        <br />
        <Button.Button8
          onClick={() => { (props.data.joined == true && props.auth.currentUser !== null) ? navigate(`my-club`) : props.signinHandler(`${props.locale.CLUB_PRMISSION_ALERT}`, `${props.locale.CLUB_NO_PRMISSION}`, props.data.joined, props.auth.currentUser, selectBanner.KMITL)}}
          style={{ marginTop: 30, marginLeft: 15, width: '20%' }}
        >
          {props.locale.CLUB_LIST_BUTTON}
        </Button.Button8>
      </div>
      </MediaQuery>
    </BoxDetail>
  </BoxWrapper>
)
