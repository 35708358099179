import styled from 'styled-components'
import ModalHandler from '../../utils/modal'
import React from 'react'
import Button from '../form/buttons'

export const Modal = styled.div`
  display: none;
  padding-top: 30px;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-animation-name: fadeIn;
  -webkit-animation-duration: 0.4s;
  animation-name: fadeIn;
  animation-duration: 0.4s;
`

export const ModalContent = styled.div`
  background-color: #fefefe;
  margin: auto;
  border: 1px solid #888;
  width: 95%;
  ${props => (props.maxWidth ? `max-width: ${props.maxWidth}px;` : null)}
`

const handleClose = (id, onClose) => {
  return () => {
    ModalHandler.closeModal(id)
    if (onClose) {
      onClose()
    }
  }
}

export const ModalHeader = ({ id, title, onClose }) => (
  <div className="modal-header">
    {title && <h3>{title}</h3>}
    <span className="close" onClick={handleClose(id, onClose)}>
      &times;
    </span>
  </div>
)

export const ModalHeaderNoTitle = ({ id, onClose }) => (
  <div style={{ padding: 10, height: 30 }}>
    <span className="close" onClick={handleClose(id, onClose)}>
      &times;
    </span>
  </div>
)

const style1 = { textAlign: 'right', borderTop: '1px solid #E8E8E8' }
export const ModalClose = ({ id, textClose = 'Close' }) => (
  <div className="modal-header" style={style1}>
    <Button.Button5 onClick={handleClose(id)}>{textClose}</Button.Button5>
  </div>
)
const Box = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-items: stretch;
  align-items: stretch;

  & button {
    width: 100%;
  }

  & .btn-close {
    border: 1px solid #892a24;
    color: #892a24;
    background: none;
    border-radius: 10px;
    padding: 5px 20px;
    cursor: pointer;
  }
`
export const ModalCloseConfirm = ({ id, onClick, textYes, textNo }) => (
  <Box className="modal-header" style={style1}>
    <div>
      <button className="btn-close" onClick={handleClose(id)}>
        {textNo}
      </button>
    </div>
    <div>
      <Button.Button5 onClick={onClick}>{textYes}</Button.Button5>
    </div>
  </Box>
)
