import React from 'react'
import styled from 'styled-components'

import { Modal, ModalContent, ModalHeader } from './index'
import LogoFacebook from '../../images/icon-facebook.png'
import LogoLine from '../../images/icon-line.png'

const Icon = styled.img`
  width: 50px;
  height: 50px;
  cursor: pointer;
`
const Icon2 = styled(Icon)`
  margin-left: 10px;
`
const Body = styled.div`
  text-align: center;
  padding: 10px;
`

const Facebook = ({ id }) => (
  <Icon src={LogoFacebook} alt="" onClick={onShareFacebook.bind(null, id)} />
)
const Line = ({ id }) => (
  <Icon2 src={LogoLine} alt="" onClick={onShareLine.bind(null, id)} />
)

function onShareFacebook(id) {
  const url = encodeURIComponent(
    `${process.env.GATSBY_API_ENDPOINT}/events/${id}/share`
  )
  window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`, '_blank')
}

function onShareLine(id) {
  const url = encodeURIComponent(
    `${process.env.GATSBY_API_ENDPOINT}/events/${id}/share`
  )
  window.open(`https://lineit.line.me/share/ui?url=${url}`, '_blank')
}

function ShareModal({ id }) {
  return (
    <Modal id="modalShare">
      <ModalContent maxWidth={300}>
        <ModalHeader id="modalShare" title="Share Link" />
        <Body>
          <Facebook id={id} />
          <Line id={id} />
        </Body>
      </ModalContent>
    </Modal>
  )
}

export default ShareModal
