import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Loading from '../components/loading'
import ShareModal from '../components/popup/share'
import View from '../components/presentation/club-list'
import { BoxGray } from '../components/template/box'
import Cookies from 'js-cookie'
import { getProfileFromId, getProfile } from '../utils/api'
import moment from 'moment'
import authActions from '../state/auth/actions'
import homeActions from '../state/home/actions'
import clubRegisterActions from '../state/club-register/actions'
import ModalHandler from '../utils/modal'

class ClubListPage extends React.Component {
  componentDidMount() {
    this.props.checkAuthorization(null, '/club-list', null, {
      onSuccess: () => this.props.onLoad(),
    })
    this.handleCheckProfile()
  }
  handleCheckProfile = async () => {
    const resData = await getProfile()
    if (resData.data && resData.data.username) {
      const userData = await getProfileFromId(resData.data.username)
      if (userData && userData.data && userData.data._id) {
        Cookies.set('_id', userData.data._id, {
          path: '/',
          expires: new Date(
            moment()
              .add(1, 'day')
              .format()
          ),
        })
        this.props.onSetProfile({
          ...userData.data,
        })
      }
    }
  }
  constructor(props) {
    super(props)
    
    this.state = {
      selectBanner: 'Default',
    }

    this.signinHandler = this.signinHandler.bind(this)
    this.defaultBanner = this.defaultBanner.bind(this)
  }
  // signinHandeler Use to select Club Banner for login 
  signinHandler (require_login, no_permission, club_auth, invitree_auth, selectBanner) {
    this.setState({selectBanner: selectBanner}, () => {
    if (invitree_auth == null) {
      //window.alert(require_login)
      ModalHandler.openModal('modalSignIn')
      return
    }
    if (club_auth == false) {
      //window.alert(no_permission)
      ModalHandler.openModal('modalJoinClub')
      return
    }
    })
  }

  // defaultBanner Use for set invitree Banner for login
  defaultBanner () {
    this.setState({selectBanner: 'Default'}, () => ModalHandler.openModal('modalSignIn'))
  }

  render() {
    if (!this.props.state.readyPageLogin) {
      return <Loading />
    }
    return (
      <Layout auth={this.props.auth.currentUser !== null}
              selectBanner={this.state.selectBanner}
              defaultBanner={this.defaultBanner}>
        <SEO keywords={[`invitree`]} />
        <BoxGray>
          <View
            onShare={this.props.onShare}
            onGoDetail={this.props.onGoDetail}
            data={this.props.clubjoined}
            auth={this.props.auth}
            locale={this.props.app.locale[this.props.app.lang]}
            signinHandler={this.signinHandler}
          />
        </BoxGray>
        <ShareModal
          id={this.props.state.selectedShareID}
          locale={this.props.app.locale[this.props.app.lang]}
        />
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    app: state.app,
    state: state.home,
    clubjoined: state.clubRegister,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      checkAuthorization: authActions.checkAuthorization,
      onShare: homeActions.onShare,
      onGoDetail: homeActions.onGoDetail,
      onLoad: homeActions.onLoadHomeLogin,
      onSetProfile: clubRegisterActions.onSetProfile,
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClubListPage)